import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { isEmpty, isNil } from "ramda";

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Avatar,
  Input,
  Select,
  MenuItem,
  FormControlLabel,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Autocomplete,
  Checkbox,
  Tooltip,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { ThemeProvider } from "@mui/material/styles";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import HelpIcon from "@mui/icons-material/Help";

import avatar from "../../../../../images/avatar.png";
import palette from "../../../../../config/colorPalette";
import { getImageSrc, getMuiTheme, validateEmail, validateSecondPassword } from "../../../../utils/utils";
import { handleChanges, handleClose, handleModalAccept } from "./utils/utils";
import { AvatarUploadBtn, ErrorText } from "./styled";

const UserModal = ({ open, setOpen, user, roles, type, acceptFunction, testId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const inputRef = useRef();
  const theme = useMemo(() => getMuiTheme(), []);
  const [localUser, setLocalUser] = useState(user);
  const [password, setPassword] = useState("");
  const [secondPassword, setSecondPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showSecondPassword, setShowSecondPassword] = useState(false);
  const [usernameError, setUsernameError] = useState();
  const [passwordError, setPasswordError] = useState();
  const [secondPasswordError, setSecondPasswordError] = useState();
  const configData = useSelector((state) => state.configData.data);

  const readOnly = type !== "edit" && type !== "create";
  const alertTypes = useMemo(() => {
    return [
      { label: "none", value: 0 },
      { label: "low", value: 1 },
      { label: "intermediate", value: 2 },
      { label: "high", value: 3 },
    ];
  }, []);
  const disabled = useMemo(
    () =>
      !validateEmail(localUser?.username) ||
      (type === "create" && !validateSecondPassword(password, secondPassword)) ||
      isNil(localUser?.role) ||
      isNil(localUser?.demarcations),
    [localUser, type, password, secondPassword],
  );
  useEffect(() => {
    if (type === "create" && localUser) {
      const shouldUpdate =
        localUser.role === undefined ||
        localUser.demarcations === undefined ||
        localUser.equipmentAlerts !== false ||
        localUser.measurementAlerts === undefined;

      if (shouldUpdate) {
        setLocalUser((prevUser) => ({
          ...prevUser,
          role: prevUser.role || "viewer",
          demarcations: Array.isArray(prevUser.demarcations) ? prevUser.demarcations : [],
          equipmentAlerts: prevUser.equipmentAlerts ?? false,
          measurementAlerts: prevUser.measurementAlerts ?? 0,
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localUser]);

  useEffect(() => {
    if (!isNil(configData) && isEmpty(configData.demarcations)) {
      setLocalUser({ ...localUser, demarcations: ["Global"], role: type === "create" ? "viewer" : localUser?.role });
    } else if (!isNil(configData) && !isEmpty(configData.demarcations)) {
      setLocalUser({ ...localUser, role: type === "create" ? "viewer" : localUser?.role });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configData]);
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Dialog maxWidth="md" open={open} onClose={() => handleClose(setLocalUser, setOpen)}>
          <DialogTitle style={{ fontSize: "x-large", color: palette.primary, fontWeight: "600" }}>
            {`${intl.formatMessage({ id: type })} ${intl.formatMessage({ id: "user" })}`}
          </DialogTitle>
          <DialogContent style={{ paddingBottom: "0" }}>
            <form autoComplete="off">
              <div>
                <Divider style={{ marginBottom: "1rem", color: palette.primary }} />
                <div style={{ display: "flex" }}>
                  <div>
                    <TextField
                      autoFocus
                      variant="outlined"
                      autoComplete="off"
                      margin="dense"
                      id="name"
                      label={intl.formatMessage({ id: "name" })}
                      type="text"
                      fullWidth
                      value={localUser?.name ?? ""}
                      onChange={(event) => setLocalUser({ ...localUser, name: event.target.value })}
                      disabled={readOnly}
                    />
                    <FormControl fullWidth variant="outlined" margin="dense">
                      <InputLabel htmlFor="email">{intl.formatMessage({ id: "email" })}*</InputLabel>
                      <OutlinedInput
                        id="email"
                        type="email"
                        required
                        autoComplete="off"
                        endAdornment={
                          <InputAdornment position="end">
                            <AlternateEmailIcon color="#e4e6ef" />
                          </InputAdornment>
                        }
                        disabled={readOnly}
                        label={`${intl.formatMessage({ id: "email" })}*`}
                        value={localUser?.username ?? ""}
                        onChange={(event) =>
                          handleChanges({
                            who: "email",
                            value: event.target.value,
                            setError: setUsernameError,
                            setData: (value) => setLocalUser({ ...localUser, username: value }),
                            intl,
                          })
                        }
                        error={usernameError ? true : false}
                        data-testid={`${testId}-email`}
                      />
                      {!isNil(usernameError) && <ErrorText>{intl.formatMessage({ id: usernameError })}</ErrorText>}
                    </FormControl>
                    <TextField
                      margin="dense"
                      autoComplete="off"
                      variant="outlined"
                      id="phone"
                      label={intl.formatMessage({ id: "phone" })}
                      type="text"
                      fullWidth
                      value={localUser?.phone ?? ""}
                      onChange={(event) => setLocalUser({ ...localUser, phone: event.target.value })}
                      disabled={readOnly}
                    />
                    <div style={{ display: "flex", alignItems: "center", marginTop: "0.5rem" }}>
                      <div style={{ minWidth: "10rem" }}>{intl.formatMessage({ id: "select.role" })}*:</div>
                      <Select
                        value={localUser?.role ?? "viewer"}
                        onChange={(event) => {
                          setLocalUser({
                            ...localUser,
                            role: event.target.value,
                            demarcations:
                              event.target.value === "admin" || event.target.value === "administrator"
                                ? ["Global"]
                                : [configData.demarcations[0]],
                          });
                        }}
                        fullWidth
                        disabled={readOnly}
                      >
                        {!isNil(roles) &&
                          roles
                            .filter((role) => role.type !== "equipment")
                            .map((role) => <MenuItem value={role.type}>{role.type}</MenuItem>)}
                      </Select>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", margin: "0.5rem 0" }}>
                      <div style={{ minWidth: "10rem", display: "flex", alignItems: "center" }}>
                        {intl.formatMessage({ id: "alerts type" })}*:
                        <Tooltip
                          title={
                            <span style={{ fontSize: "1.25rem" }}>{intl.formatMessage({ id: "alarm.tooltip" })}</span>
                          }
                        >
                          <HelpIcon style={{ marginLeft: "0.5rem" }} />
                        </Tooltip>
                      </div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={localUser?.measurementAlerts !== 0}
                            disabled={readOnly}
                            onChange={(event) => {
                              if (event.target.checked) {
                                setLocalUser({ ...localUser, measurementAlerts: 1 });
                              } else {
                                setLocalUser({ ...localUser, measurementAlerts: 0 });
                              }
                            }}
                          />
                        }
                        label={intl.formatMessage({ id: "measurement" })}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={localUser?.equipmentAlerts}
                            disabled={readOnly}
                            onChange={(event) => {
                              setLocalUser({ ...localUser, equipmentAlerts: event.target.checked });
                            }}
                          />
                        }
                        label={intl.formatMessage({ id: "equipment" })}
                      />
                    </div>

                    {!isNil(localUser?.measurementAlerts) && localUser?.measurementAlerts !== 0 && (
                      <div style={{ display: "flex", alignItems: "center", margin: "0.5rem 0" }}>
                        <div style={{ minWidth: "10rem" }}>{intl.formatMessage({ id: "alerts level" })}*:</div>
                        <Select
                          value={localUser?.measurementAlerts ?? 0}
                          defaultValue={localUser?.measurementAlerts ?? 0}
                          onChange={(event) => setLocalUser({ ...localUser, measurementAlerts: event.target.value })}
                          disabled={readOnly}
                        >
                          {!isNil(alertTypes) &&
                            alertTypes.map((item) => {
                              if (item.value === 0) {
                                return null;
                              }
                              return <MenuItem value={item.value}>{intl.formatMessage({ id: item.label })}</MenuItem>;
                            })}
                        </Select>
                      </div>
                    )}

                    {!isNil(configData) && !isNil(configData.demarcations) && !isEmpty(configData.demarcations) ? (
                      <div style={{ display: "flex", alignItems: "center", marginTop: "0.5rem" }}>
                        <div style={{ minWidth: "10rem" }}>{intl.formatMessage({ id: "select.demarcation" })}*:</div>
                        {localUser?.role === "admin" || localUser?.role === "administrator" ? (
                          <h1>Global</h1>
                        ) : (
                          <Autocomplete
                            multiple
                            id="multiple-checkboxes"
                            options={configData.demarcations}
                            disableCloseOnSelect
                            disabled={readOnly}
                            onChange={(event, values) => {
                              setLocalUser({ ...localUser, demarcations: values });
                            }}
                            getOptionLabel={(option) => option}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                                {intl.formatMessage({ id: option })}
                              </li>
                            )}
                            value={isNil(localUser?.demarcations) ? [] : localUser.demarcations}
                            style={{ width: 300 }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label={intl.formatMessage({ id: "demarcations" })}
                              />
                            )}
                          />
                        )}
                      </div>
                    ) : null}

                    <TextField
                      margin="dense"
                      autoComplete="off"
                      variant="outlined"
                      id="organization"
                      label={intl.formatMessage({ id: "organization" })}
                      type="text"
                      fullWidth
                      value={localUser?.organization ?? ""}
                      onChange={(event) => setLocalUser({ ...localUser, organization: event.target.value })}
                      disabled={readOnly}
                    />
                  </div>
                  <div
                    style={{
                      margin: "0 1rem",
                      minWidth: "15rem",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{ display: "flex", flexDirection: "column", alignItems: "center", position: "relative" }}
                    >
                      <Avatar
                        alt="avatar"
                        src={localUser?.avatar ? getImageSrc(localUser.avatar) : avatar}
                        sx={{ width: 136, height: 136 }}
                      />
                      {!readOnly && (
                        <>
                          <Input
                            ref={inputRef}
                            type="file"
                            accept="image/*"
                            style={{ display: "none" }}
                            id="avatar-input"
                            onChange={(event) => {
                              const file = event.target.files[0];
                              if (file && file.type.startsWith("image/")) {
                                const reader = new FileReader();
                                reader.onload = () => {
                                  const base64String = reader.result;
                                  setLocalUser({ ...localUser, avatar: base64String.split("base64,")?.[1] });
                                };
                                reader.readAsDataURL(file);
                              }
                            }}
                          />
                          <AvatarUploadBtn style={{ color: palette.dark, backgroundColor: `${palette.primary}70` }}>
                            <label htmlFor="avatar-input">
                              <Button variant="standard" component="span" onClick={() => inputRef.current.click()}>
                                <PhotoCameraIcon />
                              </Button>
                            </label>
                          </AvatarUploadBtn>
                        </>
                      )}
                    </div>
                    {type === "create" && (
                      <div style={{ marginRight: "5%" }}>
                        <FormControl fullWidth variant="outlined" margin="dense">
                          <InputLabel htmlFor="password">{intl.formatMessage({ id: "password" })}*</InputLabel>
                          <OutlinedInput
                            id="password"
                            type={showPassword ? "text" : "password"}
                            autoComplete="new-password"
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="visibility"
                                  onClick={() => setShowPassword(!showPassword)}
                                  onMouseDown={(event) => event.preventDefault()}
                                  edge="end"
                                  data-testid={`${testId}-visibility`}
                                >
                                  {showPassword ? <VisibilityOff color="#e4e6ef" /> : <Visibility color="#e4e6ef" />}
                                </IconButton>
                              </InputAdornment>
                            }
                            label={`${intl.formatMessage({ id: "password" })}*`}
                            value={password ?? ""}
                            onChange={(event) =>
                              handleChanges({
                                who: "password",
                                value: event.target.value,
                                setError: setPasswordError,
                                setData: setPassword,
                                intl,
                              })
                            }
                            error={passwordError ? true : false}
                            data-testid={`${testId}-password`}
                          />
                          {!isNil(passwordError) && <ErrorText>{intl.formatMessage({ id: passwordError })}</ErrorText>}
                        </FormControl>
                        <FormControl fullWidth variant="outlined" margin="dense">
                          <InputLabel htmlFor="secondPassword">
                            {intl.formatMessage({ id: "repeat password" })}*
                          </InputLabel>
                          <OutlinedInput
                            id="secondPassword"
                            type={showSecondPassword ? "text" : "password"}
                            autoComplete="off"
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="visibility"
                                  onClick={() => setShowSecondPassword(!showSecondPassword)}
                                  onMouseDown={(event) => event.preventDefault()}
                                  edge="end"
                                  data-testid={`${testId}-visibility`}
                                >
                                  {showSecondPassword ? (
                                    <VisibilityOff color="#e4e6ef" />
                                  ) : (
                                    <Visibility color="#e4e6ef" />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            label={`${intl.formatMessage({ id: "repeat password" })}*`}
                            value={secondPassword ?? ""}
                            onChange={(event) =>
                              handleChanges({
                                who: "secondPassword",
                                value: event.target.value,
                                setData: setSecondPassword,
                                setError: setSecondPasswordError,
                                password,
                                intl,
                              })
                            }
                            error={secondPasswordError ? true : false}
                            data-testid={`${testId}-password`}
                          />
                          {!isNil(secondPasswordError) && (
                            <ErrorText>{intl.formatMessage({ id: secondPasswordError })}</ErrorText>
                          )}
                        </FormControl>
                      </div>
                    )}
                  </div>
                </div>
                <Divider style={{ marginTop: "1rem" }} />
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              style={{ backgroundColor: palette.secondary, color: "white" }}
              onClick={() => handleClose(setLocalUser, setOpen)}
            >
              {intl.formatMessage({ id: type === "view" ? "close" : "cancel" })}
            </Button>
            {(type === "edit" || type === "create") && (
              <Button
                variant="contained"
                style={{
                  backgroundColor: disabled ? palette.light : palette.primary,
                }}
                disabled={disabled}
                onClick={async () => {
                  const result = await handleModalAccept(
                    { ...localUser, order: localUser.order ?? 0, password: !isEmpty(password) ? password : null },
                    type,
                    dispatch,
                    navigate,
                    intl,
                  );
                  if (result) {
                    acceptFunction();
                    handleClose(setLocalUser, setOpen);
                  }
                }}
              >
                {intl.formatMessage({ id: "accept" })}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </div>
  );
};

export default UserModal;
